header {
  height: 100vh;
 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .header__content {
    h1 {
      width: 100%;
      font-size: var(--fs-header-1);
      color: var(--white-color);
      line-height: 1;
    }

    .header__text {
      margin: 1rem 0 1rem 0;

      width: 70%;

      font-size: 1.3rem;      
      color: var(--white-color)

    }

    .header__buttons-container {
      display: flex;
      gap: 10px;
      max-width: 500px;
      width: 100%;
      margin-bottom: 4rem;

      .header__button {
        width: 100%;
        padding: 0.9rem;
        font-size: 1rem;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 600;
        border: 2px solid;
        margin-top: 1rem;
        transition: all var(--timer-short);
        .header__button-link {
          text-decoration: none;
          transition: all var(--timer-short);
        }
      }

      .header__button-orange {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);

        .header__button-link {
          color: var(--white-color);
        }

        &:hover {
          background-color: transparent;
          border-color: var(--primary-color);

          .header__button-link {
            color: var(--white-color);
          }
        }
      }

      .header__button-clear {
        background-color: transparent;
        border-color: var(--white-color);
        .header__button-link {
          color: var(--white-color);
        }
        &:hover {
          background-color: white;
          color: var(--gray-300);

          .header__button-link {
            color: var(--grey-600);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  header {
    .header__content {
      h1 {
        text-align: center;
        font-size: 3.1rem;
        width: 100%;
      }

      .header__text {
        width: 100%;
      }

      .header__buttons-container {
        margin-top: 2rem;
        flex-direction: column;
        .header__button {
          margin-top: 0;
        }
      }
    }
  }
}
