.ourteam__title {
  margin-top: 4rem;
  font-size: 2.4rem;
  color: var(--grey-500);
}

hr {
  width: 80px;
  border: 3px solid var(--secondary-color);
  margin-bottom: 1rem;
}

.ourteam__p {
  font-size: 1.1rem;
  width: 70%;
  color: var(--grey-400);
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.team__grid {
  margin-top: 4rem;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
}
