.home__work-section {
  position: absolute;
  display: grid;
  top: 90%;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 10rem;
  .home__work-card {
    position: relative;
    border-top: 5px solid var(--secondary-color);
    background-color: var(--white-color);

    box-shadow: var(--box-shadow-3);
    padding: 10px;
    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    h2 {
      position: absolute;
      top: 130px;
      text-align: center;
      width: 100%;
      font-size: 1.2rem;
      border-radius: 1px;
      text-transform: uppercase;
      color: var(--grey-500);
      padding-right: 10px;
      span {
        background-color: var(--white-color);
        padding: 1px 10px;
        border-radius: 2px;
      }
    }

    p {
      padding: 6px;
      color: var(--grey-500);
    }
  }
}
