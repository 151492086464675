.footer {
  margin-top: 15rem;
  background-color: var(--grey-200);

  .footer__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--p-0) 0px;
    img {
      height: 100px;
      width: 250px;
    }

    @media screen and (max-width: 703px) {
    
    }

    .footer__content-text {
      display: flex;
      gap: 25px;

      .footer__links {
        text-decoration: none;
        color: var(--grey-500);
        font-weight: 600;
        transition: all ease-in-out var(--timer-short);

        &:hover {
          color: var(--secondary-color);
          transform: translateY(-3px);
        }
      }
    }

    p {
      color: var(--grey-400);
    }
  }

  .footer__socials {
    display: flex;
    gap: 15px;
    .footer__social-links {
      font-size: 1.4rem;
      color: var(--grey-600);
      transition: all ease-in-out var(--timer-short);
      &:hover {
        color: var(--secondary-color);
        transform: translateY(-3px);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    padding-bottom: 67px;
    .footer__content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .footer__content-text {
        display: flex;
        flex-direction: column;
        text-align: center;
      }
    }
  }
}
