nav {
  padding: 0;
  z-index: 10;
  box-shadow: var(--box-shadow-11);
  background-color: white;
  .navbar {
    justify-content: space-between;


    .navbar__logo{
      height: 100px;
      width: 250px;
    }

    .navbar__links {
      display: flex;
      gap: var(--gap-1);

      .navlinks {
        color: var(--grey-400);
        text-decoration: none;
        text-transform: uppercase;
        transition: all ease-in-out var(--timer-short);
        .navbar__navlinks-icon {
          display: none;
        }

        &:hover {
          transform: translateY(-3px);
        }
      }

      .navlinks.active {
        color: var(--secondary-color);
        transition: all ease-in-out var(--timer-short);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  nav {
    position: fixed;
    bottom: 0;
    padding: 15px 0px;

    width: 100%;

    .navbar {
      display: flex;
      justify-content: space-evenly;

      .navbar__logo {
        display: none;
      }
      .navbar__links {
        display: flex;
        justify-content: space-between;

        .navlinks {
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          text-align: center;

          font-size: var(--fs-1);

          .navbar__navlinks-icon {
            display: flex;
            margin-bottom: 5px;
            font-size: var(--fs-2);
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}
