// Use this to set all the general styling for your application.
.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__container-width {
  width: 95%;
  max-width: 1100px;
  // Smaller screen sizes make it 100%
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__whitebg {
  color: var(--white-color);
}

.app__primarybg {
  color: var(--primary-color);
}

hr {
  width: 80px;
  border: 3px solid var(--secondary-color);
  margin-bottom: 1rem;
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  // Colour here
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}
