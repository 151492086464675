.stats__background {
  margin-top: 10rem;
  margin-bottom: 5rem;
  .stats__container {
    .stats__title {
      margin-bottom: 0.5rem;
      font-size: 2.6rem;
    }
    hr {
      width: 80px;
      border: 3px solid var(--secondary-color);
      margin-bottom: 2rem;
    }

    .stats__grid {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

      .stats__card {
        display: flex;
        flex-direction: column;
        padding: var(--p-2);
        box-shadow: var(--box-shadow-3);
        i {
          font-size: 3rem;
          margin-bottom: 1rem;
          color: var(--secondary-color);
        }

        h1 {
          color: var(--grey-500);
        }
      }

      .stats__card-1 {
        transform: translateY(50px);
      }
      @media screen and (max-width: 700px) {
        .stats__card-1{
          transform: translateY(0);
        }
      }
    }
  }
}
