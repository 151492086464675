.image__card{
  position: relative;
  box-shadow: var(--box-shadow-3);
  transition: all ease-in-out var(--timer-short);
  border-top: 5px solid var(--secondary-color);
  img{
    width: 100%;
    height: 380px;
    background-position: center;
    background-size: contain;
  }

  .imagecard__content{
    position: relative;
    padding: var(--p-1);
    h2{
      position: absolute;
      top: -38px;
      right: 0px;
      width: 100%;
      text-align: center;
      color: var(--grey-500);
      text-transform: uppercase;
      span{
        background-color: white;
        padding: 0 var(--p-1);
        border-radius: 2px;
      }
    }

    h3{
      color: var(--grey-500);
      text-align: center;
      font-weight: 500;
    }

    hr{
      width: 100%;
      border: 1px solid var(--secondary-color);
      margin-bottom: 0.5rem;
    }
    p{
      color: var(--grey-400);
    }
  }

  &:hover{
    box-shadow: var(--box-shadow-2);
  }
}

